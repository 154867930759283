/* @jsxRuntime automatic */
/* @jsxImportSource @superweb/css */

import { useFlag } from "@superweb/flags";

import { BannerNotification } from "./banner-notification";
import { DialogNotification } from "./dialog-notification";
import { useNotificationCenterState } from "../context";
import { useBlockFullscreenNotifications } from "../hooks";

const NotificationOverlay = () => {
  const { banner, closeBanner, notification, closeNotification } =
    useNotificationCenterState();
  const blockFullscreenNotification = useBlockFullscreenNotifications();

  const hasUnreadBanner = banner && !banner.is_read;
  const hasUnreadFullscreenNotification = notification && !notification.is_read;

  return (
    <>
      {hasUnreadBanner && (
        <BannerNotification banner={banner} closeBanner={closeBanner} />
      )}
      {hasUnreadFullscreenNotification && !blockFullscreenNotification && (
        <DialogNotification
          notification={notification}
          closeNotification={closeNotification}
        />
      )}
    </>
  );
};

export const NotificationOverlayWrapper = () => {
  const flag = useFlag();

  const isNotificationOverlayEnabled = flag({
    id: "f0e1a5b2-7a93-4eae-820d-78a346a9f194",
    description: "Notification overlay enabled",
  });

  return isNotificationOverlayEnabled ? <NotificationOverlay /> : null;
};
