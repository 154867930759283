// https://a.yandex-team.ru/arcadia/taxi/backend-py3/services/hiring-partners-app/docs/yaml/api/eda.yaml

import {
  useMutation,
  useQuery,
  useQueryClient,
  type UseQueryOptions,
} from "@superweb/api";

import { fetch } from "#internal/api/fetch";
import { useLocale } from "@superweb/intl";

import type { LeadFromPg } from "./leads";

export type LeadsListOnboardingResponse =
  | { status: "no_leads" | "finished" | "no_tariffs" | "no_city" }
  | LeadsListOnboardingReadyResponse;
export type LeadsListOnboardingReadyResponse = {
  status: "ready";
  content: {
    header: string;
    body: string;
    button: string;
  };
  lead: LeadFromPg;
};

const LEADS_LIST_ONBOARDING_URL = "/api/external/v1/eda/leads-list-onboarding";

const createLeadsListOnboardingQueryUrl = (options: {
  locale: Intl.Locale["language"];
}) => {
  const queryParams = new URLSearchParams(options);
  const url = `${LEADS_LIST_ONBOARDING_URL}?${queryParams.toString()}`;
  const queryKey = [LEADS_LIST_ONBOARDING_URL, options];
  return { url, queryKey };
};

export const useLeadsListOnboarding = (
  options?: UseQueryOptions<LeadsListOnboardingResponse>,
) => {
  const { language: locale } = useLocale();
  const { url, queryKey } = createLeadsListOnboardingQueryUrl({ locale });

  return useQuery<LeadsListOnboardingResponse, unknown>(
    queryKey,
    () => {
      return fetch(new Request(url, { credentials: "include" }), {
        200: (r) => r.json(),
      });
    },
    options,
  );
};

export const useLeadsListOnboardingCache = () => {
  const { language: locale } = useLocale();
  const { queryKey } = createLeadsListOnboardingQueryUrl({ locale });

  const queryClient = useQueryClient();

  return queryClient.getQueryData<LeadsListOnboardingResponse>(queryKey);
};

export const useLeadsListOnboardingMarkCompleted = () => {
  const { language: locale } = useLocale();
  const queryClient = useQueryClient();
  const url = "/api/external/v1/eda/leads-list-onboarding/mark-finished";

  return useMutation<boolean>(
    () =>
      fetch(
        new Request(url, {
          method: "POST",
          credentials: "include",
        }),
        {
          ok: () => Promise.resolve(true),
          error: () => Promise.resolve(false),
        },
      ),
    {
      onSuccess: () => {
        const { queryKey } = createLeadsListOnboardingQueryUrl({ locale });
        void queryClient.invalidateQueries(queryKey);
        queryClient.setQueryData(queryKey, undefined);
      },
    },
  );
};
